<template>
  <div>
    <div class="top">
      <div class="top_box">
        <ul class="userBox" v-if="token">
          <li @click="loginAdmin">进入后台</li>
          <li @click="logouts">退出登录</li>
        </ul>
        <ul class="userBox" v-else>
          <li @click="login(3)">注册</li>
          <li @click="login(1)">登录</li>
        </ul>
      </div>
    </div>
    <div class="logo">
      <div class="title" :style="'color:' + color">{{ getTenantData.loginTitle }}</div>
      <el-image v-if="getTenantData" class="img" :src="echoImg(getTenantData.indexLogo)"></el-image>
    </div>
    <div class="navbar" :style="'background:' + color">
      <ul class="ul">
        <li :class="current == -100 ? 'on' : ''">
          <span class="text_a" @click="goIndex"> 首页 </span>
        </li>
        <template v-for="(item, index) in navsList" @key="index">
          <li v-if="index<5" :class="current == index ? 'on' : ''">
            <span class="text_a" @click=" golink(item.navigationName, item.id, item.navigationType, index)">{{ item.navigationName }}</span>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  getTenant,
  getNavigationTemplate,
  getTenantInfo,
  websiteTemplate,
} from "@/api/template";
import { logout } from "@/api/user";
export default {
  name: "TemplateHeader",
  components: {},
  computed: {
    ...mapState(["color"]),
  },
  data() {
    return {
      navsList: [],
      current: window.sessionStorage.getItem("currentActive") || -100,
      getTenantData: "",
      token: "",
      loginCode: "",
    };
  },
  created() {
    // this.getGetTenantInfo();
    this.getGetTenant();
    this.token = window.localStorage.getItem("token");
  },
  methods: {
    init(tid) {
      let self = this;
      getNavigationTemplate(tid).then((res) => {
        if (res.code == 200) {
          this.navsList = res.data;
        } else {
          self.$message(res.msg);
        }
      }).catch(() => {});
      websiteTemplate(tid).then((res) => {
        this.$store.dispatch("saveColor", res.data.colour);
      });
    },
    getGetTenantInfo() {
      let dname = window.location.host;
      getTenantInfo({ code: dname }).then((res) => {
        if (res.data) {
          window.localStorage.setItem("domainTid", res.data.tenantId);
          this.getGetTenant();
        }
      });
    },
    getGetTenant() {
      let that = this
      getTenant().then((res) => {
        if (res.code == 200) {
          this.getTenantData = res.data;
          this.init(res.data.tid);
          window.localStorage.setItem("tenant_id", res.data.tenantId);
          window.localStorage.setItem("loginCode", res.data.loginCode);
          this.loginCode = res.data.loginCode;
          document.title = res.data.loginTitle
          if (res.data.icon) {
            var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = that.echoImg(res.data.icon);
            document.getElementsByTagName('head')[0].appendChild(link);
          }
        } else {
          this.$message(res.msg);
        }
      }).catch(() => {});
    },
    goIndex() {
      this.current = -100;
      this.$router.push("/template-fourth");
      window.sessionStorage.setItem("currentActive", "-100");
    },
    golink(navigationName, id, type, index) {
      this.current = index;
      let data = {
        name: navigationName,
        id: id,
        type: type,
      };
      window.sessionStorage.setItem("currentActive", index);
      window.sessionStorage.setItem("navigationValue", JSON.stringify(data));
      // if (this.$route.name == "fourth-list") {
      //   this.$router.push({
      //     params: { name: navigationName, id: id },
      //     name: "fourth-transfer",
      //   });
      // } else {
      //   this.$router.push({
      //     params: { name: navigationName, id: id },
      //     name: "fourth-list",
      //   });
      // }
      if(this.$route.name == 'fourth-list'){
        this.$router.push({params: {name:navigationName,id:id,type: type,index: index},name:'fourth-transfer'})
      }else {
        this.$router.push({params: {name:navigationName,id:id,type: type,index: index},name:'fourth-list'})
      }
    },
    login(active) {
      if (this.loginCode) {
        window.location.href =
          "http://" + this.loginCode + "/login?redirect=" + active;
      } else {
        window.location.href =
          process.env.VUE_APP_SAAS_URL + "/login?redirect=" + active;
      }
    },
    loginAdmin() {
      if (this.loginCode) {
        window.location.href = "http://" + this.loginCode + "/home";
      } else {
        window.location.href = process.env.VUE_APP_SAAS_URL;
      }
    },
    logouts() {
      let _token = window.localStorage.getItem("token");
      logout(_token).then(() => {
        this.token = "";
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("user");
        this.$store.dispatch("saveUser", []);
        this.$store.dispatch("saveHeadActive", "/index");
        this.$store.dispatch("saveSideActive", "/user");
        // if(this.loginCode){
        //   window.location.href = 'http://' + this.loginCode
        // }else {
        //   window.location.href = process.env.VUE_APP_SAAS_URL
        // }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.top {
  position: relative;
  height: 40px;
  background: #eee;
  .top_box {
    width: 1200px;
    margin: 0px auto;
    height: 40px;
    .userBox {
      position: absolute;
      right: 20px;
      li {
        float: left;
        line-height: 40px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
}
.logo {
  position: relative;
  width: 980px;
  height: 130px;
  margin: 0 auto;
  background: url("../images/20605563.png") no-repeat right;
  .title {
    position: absolute;
    left: 0;
    top: 48px;
    font-size: 34px;
    color: #1789e8;
  }
  .img {
    position: absolute;
    top: 18px;
    right: 20px;
    // width: 225px;
    height: 90px;
  }
}
.navbar {
  width: 100%;
  height: 60px;
  margin-bottom: 15px;
  background: url("../images/20605564.png") no-repeat center top;
  .ul {
    width: 1200px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 0 auto;
    color: #fff;

    li {
      position: relative;
      width: 156px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background: url("../images/20605566.png") no-repeat center left;
      span {
        font-size: 18px;
        cursor: pointer;
      }
      .menu {
        width: 220px;
        height: auto;
        max-height: 0;
        position: absolute;
        top: 40px;
        left: -20px;
        background-color: #fff;
        display: none;
        z-index: 9;
        box-shadow: #ccc 0px 0px 10px;
        display: none;
        overflow: hidden;
        p {
          text-align: left;
          padding-left: 20px;
          line-height: 50px;
          color: #5c5c5c;
          cursor: pointer;
          span {
            //color: #cc3333;
            display: inline-block;
            cursor: pointer;
            color: #5c5c5c;
            width: 100%;
          }
        }
      }
    }
    //.on {
    //  border-bottom: 2px #4AB344 solid;
    //  .text_a{
    //    color: #4AB344;
    //  }
    //}
    //.active{
    //  border-bottom: 2px #4AB344 solid;
    //  .text_a{
    //    color: #4AB344;
    //  }
    //  .menu{
    //    display: block;
    //    animation: myMou 1s ease-out;
    //    animation-fill-mode: forwards;
    //  }
    //}
  }
}
.imageBox {
  width: 1200px;
  height: 130px;
  margin: 10px auto 0;
  .img {
    width: 100%;
  }
}
@keyframes myMou {
  from {
    max-height: 0px;
  }
  to {
    max-height: 500px;
  }
}
</style>
